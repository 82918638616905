.background {
  height: 180px;
  filter: brightness(0.6) blur(2px);
  object-fit: cover;
}

.textarea {
  min-height: 128px;
}

.textarea-section {
  min-height: 80px;
}

.screen-height {
  height: 100vh;
}

body {
  background-color: #e8e8e8;
  color: #404040;
}

main {
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  padding-bottom: 128px;
}

nav {
  background-color: #ffffffe8;
  box-shadow: 0 -2px 8px #878787;
}

h1 {
  font-size: 1.6rem;
  margin: 0;
}

footer {
  background-color: white;
  opacity: 0.7;
}

footer {
  font-size: 0.9rem;
}

.card .body{
  font-size: 0.7rem;
}

.board {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.sections a {
  text-decoration: none;
}

.no-decoration {
  text-decoration: none;
}

.emoji {
  font-size: 0.75rem;
}

@media screen and (max-width: 575.5px) {
  .row .px-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .search-button {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
}

.profile-icon {
  width: 2rem;
  border-radius: 50%;
}

.profile-col img {
  border-radius: 50%;
  width: 8rem;
}

.profile-col .about {
  color: #888888;
}

.search-button {
  padding-left: 56px;
  padding-right: 56px;
  background-color: #f3f3f3;
}

.sign-container {
  width: 89.25px;
}

.navbar-brand {
  margin: 0;
}

.search .icon {
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  object-fit: cover;
  border-radius: 50%;
}

.search .searchDrawing {
  height: 170px;
}

.not-found {
  margin-top: 140px;
  height: 170px;
}

.course-header-img {
  height: 210px;
  filter: blur(2px);
  filter: brightness(0.5);
  object-fit: cover;
}

.video-box {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn:focus,.btn:active {
   outline: none !important;
   box-shadow: none;
}

.set-bottom {
  position: absolute;
  bottom: 20px;
}

.set-right {
  position: absolute;
  right: 20px;
}

.drive-img {
  height: 17px;
}
